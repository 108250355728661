//--------------------------------------------------------------
// CUSTOM - INDEX
//--------------------------------------------------------------

// SCSS
import '../scss/index.scss';

require('lazysizes/lazysizes'); // @see https://github.com/aFarkas/lazysizes
require('lazysizes/plugins/parent-fit/ls.parent-fit'); // @see https://github.com/aFarkas/lazysizes#parent-fit-extension
require('lazysizes/plugins/bgset/ls.bgset'); // @see https://github.com/aFarkas/lazysizes#bgset-plugin---lazy-responsive-background-image

// JS
import './menu';
import './custom';
